@use "src/styles/variables";

.rangeInput {
  position: relative;

  label {
    margin-bottom: 0;
  }

  .label {
    position: absolute;
    left: -1rem;
    top: 0.5rem;
    font-weight: 500;
    transform: translateX(-100%);
  }

  .inputsContainer {
    gap: 0.5rem;

    .inputGroup {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-inline-end: 0;

      :global(.ant-input-number.ant-input-number-in-form-item) {
        max-width: 65px;
      }

      input {
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}
