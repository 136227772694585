@use "src/styles/variables";

$headerColor: map-get(variables.$color-white, "100");
$headerBorderColor: map-get(variables.$color-white, "300");

.headerContainer {
  background-color: $headerColor;
  border-bottom: 1px solid $headerBorderColor;
  box-shadow: 0 1px 5px $headerBorderColor;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  min-height: 60px;
  gap: 2rem;
  margin-bottom: 1rem;

  .logoContainer {
    a {
      display: flex;

      svg {
        height: 34px;
      }
    }
  }

  .actionsBar {
    height: 20px;

    a {
      display: flex;
    }

    svg.actionsIcon {
      height: 18px;
      width: 18px;
      fill: #000;
    }
  }
}
