@use "src/styles/variables";

$breadcrumbColor: map-get(
  $map: variables.$color-blue,
  $key: "100",
);

.ah_breadcrumbs {
  font-size: 1rem;
  line-height: 21px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: $breadcrumbColor;

  .breadcrumbsContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .home_icon {
      width: 16px;
    }

    .breadcrumbs_next_icon {
      width: 5px;
    }

    a {
      color: $breadcrumbColor;
    }
  }
}
