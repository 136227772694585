@use "src/styles/variables";
@use "src/styles/media";

.errorPage {
  &__header {
    display: flex;
    height: 140px;
    justify-content: center;
    align-items: center;
    background-color: map-get($map: variables.$color-dark, $key: "200");
    width: 100vw;
    margin-left: -50px;

    svg {
      height: 50%;

      :global(.st1) {
        fill: #fff;
      }
    }

    @include media.sm {
      margin-left: -1rem;
    }
  }

  &__body {
    margin-top: 2rem;
    min-height: calc(100vh - 140px - 22px - 4rem);

    .errorCard {
      width: 100%;
      background-color: map-get($map: variables.$color-white, $key: "100");
      padding: 45px;
      display: flex;
      border-radius: 4px;

      &__errorNumber {
        width: 162px;
        height: 162px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 28px;
        background-color: #ffecde;
        color: #ff8126;
        font-size: 4.57rem;
        line-height: 96px;
        font-weight: 500;
      }

      &__body {
        margin-left: 1rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        color: #000;

        &__title {
          font-size: 1.57rem;
          line-height: 33px;
        }

        &__message {
          font-weight: 300;
          font-size: 1.14rem;
          line-height: 24px;
          margin: 0.5rem 0;
          flex: 1;
          display: flex;
          align-items: center;
        }

        &__footer {
          button {
            background-color: variables.$color-primary;
            border: none;

            &:hover {
              background-color: darken(variables.$color-primary, 5%);
            }
          }
        }
      }
    }
  }
}
