@use "sass:math";

$font-family: Poppins, Roboto, sans-serif;
$font-size-base: 14px;

$color-bg: #fbfbfd;
$color-primary: #3ebfc2;
$color-font-primary: #282a3c;
$color-logo: #3ab9bf;

$color-dark: (
  "100": #12151d,
  "200": #242939,
  "300": #282a3c,
);

$color-blue: (
  "100": #767da5,
  "150": #6d7ebe,
  "200": #bcc7ff,
  "500": #f0f2ff,
);

$color-white: (
  "100": #fff,
  "200": #fafafc,
  "250": #f2f3f8,
  "300": #e9e9e9,
  "350": #d0d0d0,
);

$color-gray: (
  "100": #aaa,
  "150": #a1a8c3,
  "200": #b5b5b5,
  "300": #ced4da,
  "400": #dddfec,
  "500": #e1e3ee,
  "550": #e0e2ef,
);

$color-green: (
  "100": #379688,
  "200": #46baaa,
  "300": #90d6cc,
  "500": #a9efe5,
);

@function px2rem($pxVal) {
  @return math.div($pxVal, $font-size-base) * 1rem;
}

$font-sizes: (
  "h1": 2.488rem,
  "h2": 2.074rem,
  "h3": 1.728rem,
  "h4": 1.44rem,
  "large": 1.167rem,
  "body": 1rem,
  "small": 0.833rem,
  "smallest": 0.694rem,
);

$color-border: map-get(
  $map: $color-white,
  $key: "300",
);
