@use 'src/styles/animations';

.alertsContainer {
  position: fixed;
  width: clamp(50vw, 800px, calc(100vw - 30px));
  top: clamp(70px, 5vh, 100px);
  left: 50vw;
  transform: translateX(-50%);
  z-index: 10;

  .ah_alert {
    position: relative;
    animation-duration: 0.5s;

    &.showing {
      @include animations.fadeInUp;
    }

    &.hiding {
      @include animations.fadeOutUp;
    }

    &.shaking {
      @include animations.shake;
      animation-duration: 0.3s;
    }

    button {
      &:focus {
        outline: none;
      }
    }
  }
}
