@mixin fadeInUp {
  animation-name: keyFadeInUp;
  animation-fill-mode: forwards;
}

@mixin fadeOutUp {
  animation-name: keyFadeOutUp;
  animation-fill-mode: forwards;
}

@mixin shake {
  animation-name: keyShake;
}

@keyframes keyFadeInUp {
  from {
    top: 50px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes keyFadeOutUp {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -50px;
    opacity: 0;
  }
}

@keyframes keyShake {
  0% {
    transform: rotate(0);
  }
  11.1% {
    transform: rotate(-1deg);
  }
  33.3% {
    transform: rotate(1deg);
  }
  55.5% {
    transform: rotate(-1deg);
  }
  77.7% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}
