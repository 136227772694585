$xxs: 372px;
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1400px;

@mixin xxs {
  @media (max-width: #{$xxs}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$xs}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$xl}) {
    @content;
  }
}
