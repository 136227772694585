@use "src/styles/variables";

.userMenuDefaultIcon {
  width: 20px !important;
  height: 20px !important;
}

.userMenuImage {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.userMenuItem {
  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
