@use "./variables";
@use "./media";

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Light.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Light.woff") format("woff"),
    url("../assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Regular.woff") format("woff"),
    url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Medium.woff") format("woff"),
    url("../assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-SemiBold.woff") format("woff"),
    url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("../assets/fonts/Poppins-Bold.woff") format("woff"),
    url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

* {
  box-sizing: border-box;
  font-family: variables.$font-family;
}

html {
  font-size: variables.$font-size-base;
}

body {
  font-size: map-get(variables.$font-sizes, "body");
  line-height: variables.px2rem(18px);

  margin: 0;
  padding: 0;
  background-color: variables.$color-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: map-get(variables.$color-dark, "300");

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
}

.vm-form {
  .form-title {
    font-size: 0.875rem;
  }
}

.info-tooltip-icon {
  width: 11px;
  height: 11px;
  margin-top: -3px;
  cursor: help;
}

.base-container {
  padding: 0 50px;

  @include media.sm {
    padding: 0 15px;
  }
}

.alert-primary {
  color: #f0f2f4;
  background-color: #6b7994;
  border-color: #566176;
}

$h1-size: map-get(variables.$color-dark, "200");

h1 {
  font-size: map-get($map: variables.$font-sizes, $key: "h1");
  font-weight: 500;
}

h2 {
  font-size: map-get($map: variables.$font-sizes, $key: "h2");
  font-weight: 500;
}

h3 {
  font-size: map-get($map: variables.$font-sizes, $key: "h3");
  font-weight: 500;
}

h4 {
  font-size: map-get($map: variables.$font-sizes, $key: "h4");
  font-weight: 500;
}

a {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

.text-lg {
  font-size: map-get($map: variables.$font-sizes, $key: "large") !important;
}

.text-base {
  font-size: map-get($map: variables.$font-sizes, $key: "body") !important;
}

.text-sm {
  font-size: map-get($map: variables.$font-sizes, $key: "small") !important;
}
