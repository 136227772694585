@use "src/styles/variables";
@use "../Header.module" as headerStyles;

$linkColor: map-get(variables.$color-dark, "200");
$linkHoverColor: darken(headerStyles.$headerColor, 10%);
$activeLinkColor: variables.$color-primary;

.navMenu {
  flex: 1;
}

.menuItem {
  padding-inline: 0.75rem !important;
  user-select: none;

  &.activeItem {
    .itemContent {
      color: $activeLinkColor !important;
    }

    svg {
      fill: $activeLinkColor;
    }
  }

  .itemContent {
    color: $linkColor !important;
  }

  svg {
    transition: transform 0.5s ease;
    fill: $linkColor;

    &.open {
      transform: rotateX(180deg);
    }
  }

  &:after {
    display: none;
  }

  &:not(&[aria-disabled="true"]) {
    &:hover {
      background-color: $linkHoverColor !important;
    }
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    .itemContent {
      pointer-events: none;
      color: lighten($linkColor, 50%) !important;
    }
  }
}

.navSubMenu {
  padding-top: 0;
}
