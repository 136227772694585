@use "src/styles/variables";

.pill {
  display: flex;
  align-items: center;
  height: 22px;
  padding: 2px 14px;
  gap: 0.5rem;
  color: #6d7ebe;
  background: #fff;
  font-size: variables.px2rem(12px);
  line-height: variables.px2rem(18px);
  font-weight: 400;
  border-radius: 11px;

  .close {
    background-color: transparent;
    height: 100%;
    border: none;
    border-radius: 5px;
    color: #6d7ebe;

    &:hover {
      background-color: rgba(darken(#fff, 20%), 0.5);
    }

    &.hidden {
      display: none;
    }
  }
}
