@use "src/styles/variables";

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    border-radius: 50%;
    border-style: solid;
    border-color: map-get($map: variables.$color-white, $key: "100");
    border-width: 5px;
    border-top-color: variables.$color-primary;
    width: 35px;
    height: 35px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
