@use "src/styles/variables";
@use "src/styles/media";

$text-color: map-get(variables.$color-white, "100");

.authLayoutMain {
  min-height: max(calc(100vh - 22px - 2rem), 600px);
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    width: clamp(50vw, 800px, 100%);
    background-color: map-get(variables.$color-dark, "200");
    padding: 3rem;
    border-radius: 5px;
    color: $text-color;
    min-height: 50vh;

    @include media.md {
      padding: 1.5rem;
    }

    .logo {
      height: 80px;

      :global(.st1) {
        fill: #fff;
      }
    }

    .authFormContent {
      width: clamp(75%, 500px, 100%);
      margin: auto auto;
      color: inherit;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .infoText {
        font-size: 0.8rem;
        line-height: 1rem;
      }

      .errorText {
        color: #f5222d;
      }

      h3 {
        text-align: center;
      }

      .authButton {
        width: fit-content;
        min-width: 100px;
        background-color: map-get(variables.$color-white, "100");
        color: map-get(variables.$color-dark, "100");
        font-size: 1.28rem;
        line-height: 24px;
        font-weight: 500;
        padding: 0.5rem 1rem;
        height: unset;

        &:disabled {
          background-color: map-get(variables.$color-gray, "100");
          border-color: map-get(variables.$color-gray, "100");
        }

        &:not(:disabled) {
          &:hover {
            background-color: map-get($map: variables.$color-blue, $key: "100");
            border-color: map-get($map: variables.$color-blue, $key: "100");
          }
        }
      }
    }
  }
}
