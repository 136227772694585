@use "src/styles/variables";

.footer {
  margin: 1rem 0;
  background: unset;

  p {
    margin-bottom: 0;
    color: map-get($map: variables.$color-gray, $key: "200");
    font-weight: 300;
    font-size: 1rem;
    line-height: 22px;
  }
}
